// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	ridirecUri: 'http://localhost:4500/login',
	//ridirecUri : 'https://www.purple-u.com/login',

	production: false,
	default_lang: 'US',
	comId: 'LOOALTST',
	comCd: '00',
	apiUrl: 'https://v365.wowcns.net/api',
    //apiUrl: 'http://localhost:8100/api',
	urlWownet: 'https://v365.wowcns.net',
};
